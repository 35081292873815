<script setup>
import store from "@/store";
import { computed, ref } from "vue";

const loading = ref(false);
const connect = async () => {
  await store.dispatch("auth/connect", true);
};

const disconnect = async () => {
  await store.dispatch("auth/disconnect", true);
};

// const openMintModal = () => {
//   mintModalOpen.value = true;
// };
// const closeMintModal = () => {
//   mintModalOpen.value = false;
// };

const mint = async () => {
  loading.value = true;
  await store.dispatch("auth/mint", 0);
  loading.value = false;
  //   closeMintModal();
};

// const getTokens = async () => {
//   tokens.value = await store.dispatch("auth/getTokens");
// };

const connection = computed(() => {
  return store.state.auth.connection;
});
</script>

<template>
  <div class="bg-worst-orange p-2 text-center font-almendra">
    <div class="container">
      Season 3 coming soon! Sponsored by Nouns DAO • Learn more at
      <a href="https://nouns.wtf/vote/179" target="_blank" class="underline"
        >nouns.wtf/vote/179</a
      >
    </div>
  </div>
  <div
    class="lg:flex justify-center items-center bg-neon-yellow p-3 z-40 hidden"
  >
    <div class="font-almendra mx-4 underline uppercase">
      <a href="https://discord.gg/ca5fqu7B3h" target="_blank">Discord</a>
    </div>
    <div class="font-almendra mx-4 underline uppercase">
      <a
        href="https://www.etsy.com/listing/1409573501/corn-o-clock?click_key=bb7419fdd547b26a3deb[…]529225a%3A1409573501&click_sum=0a19869f&ref=shop_home_active_1"
        target="_blank"
        >Corn o'clock</a
      >
    </div>
    <div style="height: 60px; z-index: 1000" class="mx-6">
      <img :src="require('../assets/temp/wcd.gif')" style="max-height: 200px" />
    </div>
    <div class="font-almendra mx-4 underline uppercase">
      <a href="https://twitter.com/worstdao" target="_blank">Twitter</a>
    </div>
    <div class="font-almendra mx-4 underline uppercase">
      <a href="https://www.instagram.com/worstdao/" target="_blank"
        >Instagram</a
      >
    </div>
  </div>
  <div
    class="flex flex-col lg:hidden justify-center items-center bg-neon-yellow p-3 z-40"
  >
    <div class="flex justify-between mb-4 text-center items-center text-sm">
      <div class="font-almendra mx-4 underline uppercase">
        <a href="https://discord.gg/ca5fqu7B3h" target="_blank">Discord</a>
      </div>
      <div class="font-almendra mx-4 underline uppercase">
        <a
          href="https://www.etsy.com/listing/1409573501/corn-o-clock?click_key=bb7419fdd547b26a3deb[…]529225a%3A1409573501&click_sum=0a19869f&ref=shop_home_active_1"
          target="_blank"
          >Corn o'clock</a
        >
      </div>
      <div class="font-almendra mx-4 underline uppercase">
        <a href="https://twitter.com/worstdao" target="_blank">Twitter</a>
      </div>
      <div class="font-almendra mx-4 underline uppercase">
        <a href="https://www.instagram.com/worstdao/" target="_blank"
          >Instagram</a
        >
      </div>
    </div>
    <div style="height: 60px; z-index: 1000" class="mx-6">
      <img :src="require('../assets/temp/wcd.gif')" style="max-height: 200px" />
    </div>
  </div>
</template>

<style scoped></style>
