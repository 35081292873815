<script setup>
import NavComponent from "@/components/NavComponent";
import TickerComponent from "@/components/TickerComponent";
import VideoListItemComponent from "@/components/VideoListItemComponent";
import { computed, ref, reactive, onMounted } from "@vue/runtime-core";
import store from "@/store";
import VideoComponent from "@/components/VideoComponent.vue";

const seasons = computed(() => {
  return store.state.seasons.list;
});

const seasonIndex = ref(1);

const selectSeason = (s) => {
  seasonIndex.value = s;
};

const selectedSeason = computed(() => {
  return seasons.value?.find((s) => s.number === seasonIndex.value);
});

const countDownDate = ref(new Date("Feb 15, 2023 00:00:00").getTime());

// Update the count down every 1 second
const timer = ref(null);
const timeComp = reactive({
  hours: null,
  minutes: null,
  seconds: null,
});

const formatNumber = (num) => {
  return parseInt(num).toLocaleString("en-US", { maximumFractionDigits: 2 });
};

const scrollToSignup = (event) => {
  event.preventDefault();
  document.querySelector("#signup").scrollIntoView({
    behavior: "smooth",
  });
};
onMounted(() => {
  timer.value = setInterval(function () {
    // Get today's date and time
    const now = new Date().getTime();

    // Find the distance between now and the count down date
    const distance = countDownDate.value - now;

    // Time calculations for days, hours, minutes and seconds
    // timeComp.days = Math.floor(distance / (1000 * 60 * 60 * 24));
    timeComp.hours = Math.floor(distance / (1000 * 60 * 60));
    timeComp.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    timeComp.seconds = Math.floor((distance % (1000 * 60)) / 1000);

    // Display the result in the element with id="demo"
    //   document.getElementById("demo").innerHTML = days + "d " + hours + "h "
    //   + minutes + "m " + seconds + "s ";

    //   // If the count down is finished, write some text
    //   if (distance < 0) {
    //     clearInterval(x);
    //     document.getElementById("demo").innerHTML = "EXPIRED";
    //   }
  }, 1000);
});

const email = ref(null);
const loading = ref(false);
const errors = ref(null);
const success = ref(null);
const signup = async () => {
  errors.value = null;
  if (!email.value) {
    errors.value = "Email required";
    return;
  }
  loading.value = true;
  try {
    await store.dispatch("auth/signup", email.value);
  } catch (e) {
    console.log(e);
    errors.value =
      e?.email?.[0] ??
      "Error signing up - make sure you have entered a properly formatted email address and try again";
    return;
  }

  loading.value = false;
  success.value = "You're on the list!";
};
</script>
<template>
  <div
    class="bg-repeat w-full min-h-screen"
    :style="{
      'background-image': `url('${require('../assets/static-images/brick-bg.jpg')}')`,
    }"
  >
    <NavComponent />
    <TickerComponent />
    <div class="relative mb-6">
      <img
        :src="require('../assets/temp/wcd-header-new2.jpg')"
        class="relative"
      />
    </div>

    <!-- <div
      class="font-impact text-center px-4 text-4xl lg:text-6xl xl:text-8xl mb-6"
    >
      <a
        href="https://zora.co/collections/0x24a7b986793bca0c8c5b04dc2aaffe9e8df921ad"
        target="_blank"
      >
        GET AS MANY TRAILERS AS YOU CAN<br class="hidden md:block" />
        IT WILL MATTER LATER
      </a>
    </div> -->

    <!-- <div class="video-wrap flex flex-col justify-center items-center mb-6">
      <div class="trailer-title font-allerta text-2xl text-center px-8">
        SEASON 1 TRAILER
      </div>
    </div> -->

    <div class="flex justify-center items-center mb-8 relative px-4">
      <!-- <div class="never-forget">Never forget your loved ones</div>
      <div class="embed-youtube">
        <iframe
          src="https://player.vimeo.com/video/789161270?h=e233d9f150"
          width="640"
          height="360"
          frameborder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div> -->
      <div class="max-w-2xl">
        <router-link :to="{ name: 'season', params: { season: 1 } }">
          <img
            :src="require('../assets/launch/show-season-1.png')"
            class="relative"
          />
        </router-link>
      </div>
    </div>

    <!-- <div class="flex justify-center items-center mb-3 relative px-4">
      <div class="max-w-xl">
        <a
          href="https://zora.co/collections/0x24a7b986793bca0c8c5b04dc2aaffe9e8df921ad"
          target="_blank"
        >
          <img
            :src="require('../assets/splash/wcd-season1-believe.png')"
            class="relative"
          />
        </a>
      </div>
    </div> -->

    <div class="flex justify-center items-center mb-16 relative px-4">
      <div class="max-w-xl">
        <router-link :to="{ name: 'season', params: { season: 1 } }">
          <img
            :src="require('../assets/splash/wcd-season1-characters.png')"
            class="relative"
          />
        </router-link>
      </div>
    </div>

    <div class="flex justify-center items-center mb-3 relative px-4">
      <div class="max-w-xl">
        <router-link :to="{ name: 'season', params: { season: 1 } }">
          <img
            :src="require('../assets/launch/down-arrows.png')"
            class="relative max-w-md"
          />
        </router-link>
      </div>
    </div>

    <div class="flex justify-center items-center mb-8 relative px-4">
      <div class="max-w-6xl">
        <a
          href="https://opensea.io/collection/worst-case-decision-season-1-trailer"
          target="_blank"
        >
          <img
            :src="require('../assets/launch/go-here-trailer.png')"
            class="relative"
          />
        </a>
      </div>
    </div>

    <!-- <div class="flex justify-center items-center mb-0 relative px-4">
      <div class="max-w-6xl">
        <img
          :src="require('../assets/splash/wcd-season1-preview-block.png')"
          class="relative"
        />
      </div>
    </div> -->

    <div class="flex justify-center items-center relative px-4 -mb-44 z-30">
      <div class="max-w-6xl">
        <img
          :src="require('../assets/splash/wcd-season1-ship.png')"
          class="relative"
        />
      </div>
    </div>

    <!-- <div class="flex items-center flex-col justify-center mt-8 mb-8 font-akaya">
      <div class="drops-in text-5xl underline mb-4">DROPS IN</div>
      <div class="countdown text-5xl">
        {{
          `${formatNumber(timeComp.hours)} Hours : ${timeComp.minutes}
                                Min
                                : ${timeComp.seconds} Sec`
        }}
      </div>
    </div> -->

    <!-- <div
      class="flex justify-end font-berkshire text-2xl p-8"
      style="color: #427324"
    >
      get fortified
    </div> -->

    <div class="relative">
      <img
        :src="require('../assets/temp/wcd-corn.jpg')"
        class="relative w-full"
      />
    </div>

    <div class="relative mb-16">
      <img
        :src="require('../assets/temp/wcd-cat.jpg')"
        class="relative w-full"
      />
    </div>

    <div class="relative">
      <img
        :src="require('../assets/temp/wcd-00.jpg')"
        class="relative w-full"
      />
    </div>

    <div class="relative justify-center items-center w-full flex">
      <div class="w-1/12 relative"></div>
      <img :src="require('../assets/temp/wcd-01.jpg')" class="relative w-3/4" />
      <div class="w-1/12 relative">
        <div
          class="font-rocker rotate-90 text-center text-xl w-64 absolute -left-32 md:-left-24"
        >
          fortune favors the worst
        </div>
      </div>
    </div>

    <div class="relative justify-center items-center w-full flex mb-2">
      <div class="w-1/5 relative">
        <div class="font-acme text-center text-lg text-worst-orange">
          live in the infinite moment
        </div>
      </div>
      <img :src="require('../assets/temp/wcd-02.jpg')" class="relative w-3/5" />
      <div class="w-1/5 relative"></div>
    </div>

    <div class="relative flex justify-center mb-4">
      <img :src="require('../assets/temp/wcd-03.jpg')" class="relative w-5/6" />
    </div>

    <div class="relative justify-center items-center w-full flex mb-6">
      <div class="w-1/5 relative"></div>
      <img
        :src="require('../assets/temp/wcd-04.jpg')"
        class="relative w-3/5 z-50"
      />
      <div class="w-1/5 relative">
        <div class="font-ibm text-center text-lg md:text-5xl font-light">
          h<br />e<br />l<br />l<br />o<br /><br />fri<br />end
        </div>
      </div>
    </div>

    <div class="relative justify-center items-center w-full flex mb-6">
      <div class="w-1/3 relative text-center">
        <div class="font-irish text-4xl" style="color: #2784bf">
          Always be Smiling
        </div>
      </div>
      <div class="relative w-1/2 flex justify-center z-10">
        <img :src="require('../assets/temp/wcd-05.jpg')" class="w-1/2" />
      </div>
      <div class="w-1/3 relative">
        <!-- <div class="left-4 w-2 h-2 top-0 overflow-visible bg-red-500"> -->
        <img
          :src="require('../assets/temp/wcd-man.png')"
          style="width: 500px; left: -50%; top: -50px"
          class="absolute z-20"
        />
        <!-- </div> -->
      </div>
    </div>

    <!-- <div class="relative flex justify-center">
      <img
        :src="require('../assets/temp/wcd-06.jpg')"
        class="relative w-full"
      />
    </div> -->

    <div
      class="bg-white flex items-center relative justify-center flex-col pb-48 md:pb-0"
      :style="{
        background: `url('${require('../assets/splash/wcd-season1-signup-bg.png')}')`,
      }"
    >
      <a href="https://www.hashku.com" target="_blank">
        <img
          :src="require('../assets/splash/wcd-season1-signup-bg.png')"
          class=""
        />
      </a>
      <div
        class="absolute left-0 top-0 right-0 bottom-0 w-full h-full flex justify-center items-center"
      >
        <div
          class="p-8 md:px-28 md:py-16 relative z-10"
          style="background-color: #deff15"
          name="signup"
          id="signup"
        >
          <img
            :src="require('../assets/temp/wcd-corndrwing.png')"
            class="absolute -top-6 z-0"
            style="width: 200px; left: -300px"
          />
          <div v-if="!success" class="flex flex-col items-center text-center">
            <div class="oh-hi">OH-HI</div>
            <div class="mb-4">
              <input
                v-model="email"
                placeholder="Email"
                class="forminput md:text-2xl px-4 py-2 rounded"
              />
            </div>
            <div class="mb-4">
              <button
                @click="signup"
                class="rounded bg-black font-acme md:text-3xl text-white py-3 px-6"
              >
                Get ME on the Special List
              </button>
            </div>
            <div v-if="errors" class="text-xl font-allerta text-worst-orange">
              {{ errors }}
            </div>
          </div>
          <div v-if="success" class="text-xl font-allerta">{{ success }}</div>
        </div>
      </div>
      <a
        href="https://www.hashku.com"
        target="_blank"
        class="absolute right-0 bottom-0"
      >
        <img :src="require('../assets/splash/wcd-hashku.jpg')" class="" />
      </a>
      <!-- <div class="p-8 w-4/5">
        <img :src="require('../assets/temp/wcd-07.jpg')" class="w-full" />
      </div>
      <div class="flex flex-col w-1/5 justify-center items-center">
        <div class="mb-4">
          <img :src="require('../assets/temp/wcd-08-mh.jpg')" class="" />
        </div>
        <div>
          <img :src="require('../assets/temp/wcd-08-hashku.jpg')" class="" />
        </div>
      </div> -->
    </div>

    <div class="flex justify-center relative left-auto right-auto"></div>

    <!-- <div
      class="video-wrap flex flex-col justify-center items-center mt-12 mb-8"
    >
      <div class="trailer-title font-allerta text-2xl text-center px-8">
        SNEAK PEEK EPISOD DPISODE E II
      </div>
    </div>
    <div class="flex justify-center items-center mb-16 relative">
      <div class="embed-youtube">
        <iframe
          width="1000"
          height="543"
          src="https://www.youtube.com/embed/KpH1Yve1jsM"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    </div> -->

    <div class="pb-24"></div>
  </div>
</template>

<style lang="pcss" scoped>
.embed-youtube {
    filter: drop-shadow(50px 50px 4px rgba(0, 0, 0, 0.11));
}

@media only screen and (max-width: 1020px) {
    .embed-youtube {
        position: relative;
        padding-bottom: 56.25%; /* - 16:9 aspect ratio (most common) */
        height: 0;
        overflow: hidden;
        flex:1;
        filter: drop-shadow(50px 50px 4px rgba(0, 0, 0, 0.11));
        margin-left: 1rem;
        margin-right: 1rem;
        z-index: 200;
    }

    .embed-youtube iframe,
    .embed-youtube object,
    .embed-youtube embed {
        border: 0;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 250;
    }
}
.trailer-title {
    text-shadow: -1px 0 #6C1F93, 0 1px #6C1F93, 1px 0 #6C1F93, 0 -1px #6C1F93;
    color: #CBCAAE;
}
.video-wrap {
    filter: drop-shadow(50px 50px 4px rgba(0, 0, 0, 0.11));
}

.drops-in {
    color: #FE5D03;
    /* border: 1px solid #000000; */
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
    position: relative;
    z-index: 100;
}

.oh-hi {
    color: #FE5D03;
    /* border: 1px solid #000000; */
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
    position: relative;
    z-index: 100;
    @apply font-akaya text-4xl underline mb-6;
}

.countdown {
    color: #762DD8;
    padding: 0 1rem;
    @apply text-center;
}
.never-forget {
    width: 200px;
    top: 400px;
    z-index: 0;
    @apply font-allerta hidden md:block absolute text-center text-lg md:left-8 lg:left-12;
}

.forminput {
    box-shadow: inset 2px 3px 3px rgba(0, 0, 0, 0.25);
}
</style>
