import { createApp } from "vue";
import { setBaseUrl } from "./request";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "@/styles/index.css";

import "@fontsource/acme/400.css";
import "@fontsource/advent-pro/400.css";
import "@fontsource/akaya-telivigala/400.css";
import "@fontsource/allerta-stencil/400.css";
import "@fontsource/almendra/400.css";
import "@fontsource/anton/400.css";
import "@fontsource/atomic-age/400.css";
import "@fontsource/baloo-da-2/latin.css";
import "@fontsource/berkshire-swash/400.css";
import "@fontsource/ibm-plex-serif/300.css";
import "@fontsource/inter/400.css";
import "@fontsource/irish-grover/400.css";
import "@fontsource/lato/300-italic.css";
import "@fontsource/lato/300.css";
import "@fontsource/lato/400-italic.css";
import "@fontsource/lato/400.css";
import "@fontsource/new-rocker/400.css";

import "video.js/dist/video-js.css";

if (process.env.VUE_APP_API_URL) {
  setBaseUrl(process.env.VUE_APP_API_URL);
}

createApp(App).use(store).use(router).mount("#app");
