export const seasonOne = {
  1: require("../assets/json/season-1/1.json"),
  2: require("../assets/json/season-1/2.json"),
  3: require("../assets/json/season-1/3.json"),
  4: require("../assets/json/season-1/4.json"),
  5: require("../assets/json/season-1/5.json"),
  6: require("../assets/json/season-1/6.json"),
  10: require("../assets/json/season-1/10.json"),
  11: require("../assets/json/season-1/11.json"),
  12: require("../assets/json/season-1/12.json"),
  13: require("../assets/json/season-1/13.json"),
  14: require("../assets/json/season-1/14.json"),
  21: require("../assets/json/season-1/21.json"),
  31: require("../assets/json/season-1/31.json"),
  32: require("../assets/json/season-1/32.json"),
  41: require("../assets/json/season-1/41.json"),
  42: require("../assets/json/season-1/42.json"),
  43: require("../assets/json/season-1/43.json"),
  68: require("../assets/json/season-1/68.json"),
  101: require("../assets/json/season-1/101.json"),
  102: require("../assets/json/season-1/102.json"),
  103: require("../assets/json/season-1/103.json"),
  104: require("../assets/json/season-1/104.json"),
  105: require("../assets/json/season-1/105.json"),
  106: require("../assets/json/season-1/106.json"),
  107: require("../assets/json/season-1/107.json"),
  108: require("../assets/json/season-1/108.json"),
  109: require("../assets/json/season-1/109.json"),
  110: require("../assets/json/season-1/110.json"),
  111: require("../assets/json/season-1/111.json"),
  112: require("../assets/json/season-1/112.json"),
  113: require("../assets/json/season-1/113.json"),
  114: require("../assets/json/season-1/114.json"),
  115: require("../assets/json/season-1/115.json"),
  116: require("../assets/json/season-1/116.json"),
  117: require("../assets/json/season-1/117.json"),
  118: require("../assets/json/season-1/118.json"),
  119: require("../assets/json/season-1/119.json"),
  120: require("../assets/json/season-1/120.json"),
  121: require("../assets/json/season-1/121.json"),
  122: require("../assets/json/season-1/122.json"),
  123: require("../assets/json/season-1/123.json"),
  124: require("../assets/json/season-1/124.json"),
  125: require("../assets/json/season-1/125.json"),
  126: require("../assets/json/season-1/126.json"),
  127: require("../assets/json/season-1/127.json"),
  128: require("../assets/json/season-1/128.json"),
  129: require("../assets/json/season-1/129.json"),
  130: require("../assets/json/season-1/130.json"),
  131: require("../assets/json/season-1/131.json"),
  132: require("../assets/json/season-1/132.json"),
  133: require("../assets/json/season-1/133.json"),
  134: require("../assets/json/season-1/134.json"),
  135: require("../assets/json/season-1/135.json"),
  136: require("../assets/json/season-1/136.json"),
  137: require("../assets/json/season-1/137.json"),
  138: require("../assets/json/season-1/138.json"),
  139: require("../assets/json/season-1/139.json"),
  140: require("../assets/json/season-1/140.json"),
  141: require("../assets/json/season-1/141.json"),
  142: require("../assets/json/season-1/142.json"),
  143: require("../assets/json/season-1/143.json"),
  144: require("../assets/json/season-1/144.json"),
  145: require("../assets/json/season-1/145.json"),
  146: require("../assets/json/season-1/146.json"),
  147: require("../assets/json/season-1/147.json"),
  148: require("../assets/json/season-1/148.json"),
  149: require("../assets/json/season-1/149.json"),
  150: require("../assets/json/season-1/150.json"),
  151: require("../assets/json/season-1/151.json"),
  152: require("../assets/json/season-1/152.json"),
  153: require("../assets/json/season-1/153.json"),
  154: require("../assets/json/season-1/154.json"),
  155: require("../assets/json/season-1/155.json"),
  156: require("../assets/json/season-1/156.json"),
  157: require("../assets/json/season-1/157.json"),
  158: require("../assets/json/season-1/158.json"),
  159: require("../assets/json/season-1/159.json"),
  160: require("../assets/json/season-1/160.json"),
  161: require("../assets/json/season-1/161.json"),
  162: require("../assets/json/season-1/162.json"),
  163: require("../assets/json/season-1/163.json"),
  164: require("../assets/json/season-1/164.json"),
};
