<script setup></script>

<template>
  <div class="ticker-wrap z-30">
    <div class="ticker">
      <div class="ticker-wrap-item">
        <div class="flex items-center">
          <span class="mr-2">
            <img src="/static-images/eth.png" style="height: 20px" />
          </span>
          <span class="mr-2"><strong>Ethereum</strong></span>
          <span class="mr-2"> 1547.76 </span>
          <span class="text-green-400">+41.47 (+2.75%)</span>
        </div>
      </div>
      <div class="ticker-wrap-item">
        <div class="flex items-center">
          <span class="mr-2">
            <img src="/static-images/btc.png" style="height: 20px" />
          </span>
          <span class="mr-2"><strong>Bitcoin</strong></span>
          <span class="mr-2"> 22093.10 </span>
          <span class="text-green-400">+305.30 (+1.40%)</span>
        </div>
      </div>
      <div class="ticker-wrap-item">
        <div class="flex items-center">
          <span class="mr-2">
            <img src="/static-images/usd-eur.png" style="height: 20px" />
          </span>
          <span class="mr-2"><strong>EUR</strong></span>
          <span class="mr-2"> 1.07 </span>
          <span class="text-green-400">+0.01 (+0.15%)</span>
        </div>
      </div>
      <div class="ticker-wrap-item">
        <div class="flex items-center">
          <span class="mr-2">
            <img src="/static-images/eth.png" style="height: 20px" />
          </span>
          <span class="mr-2"><strong>Ethereum</strong></span>
          <span class="mr-2"> 1547.76 </span>
          <span class="text-green-400">+41.47 (+2.75%)</span>
        </div>
      </div>
      <div class="ticker-wrap-item">
        <div class="flex items-center">
          <span class="mr-2">
            <img src="/static-images/btc.png" style="height: 20px" />
          </span>
          <span class="mr-2"><strong>Bitcoin</strong></span>
          <span class="mr-2"> 22093.10 </span>
          <span class="text-green-400">+305.30 (+1.40%)</span>
        </div>
      </div>
      <div class="ticker-wrap-item">
        <div class="flex items-center">
          <span class="mr-2">
            <img src="/static-images/usd-eur.png" style="height: 20px" />
          </span>
          <span class="mr-2"><strong>EUR</strong></span>
          <span class="mr-2"> 1.07 </span>
          <span class="text-green-400">+0.01 (+0.15%)</span>
        </div>
      </div>
      <div class="ticker-wrap-item">
        <div class="flex items-center">
          <span class="mr-2">
            <img src="/static-images/eth.png" style="height: 20px" />
          </span>
          <span class="mr-2"><strong>Ethereum</strong></span>
          <span class="mr-2"> 1547.76 </span>
          <span class="text-green-400">+41.47 (+2.75%)</span>
        </div>
      </div>
      <div class="ticker-wrap-item">
        <div class="flex items-center">
          <span class="mr-2">
            <img src="/static-images/btc.png" style="height: 20px" />
          </span>
          <span class="mr-2"><strong>Bitcoin</strong></span>
          <span class="mr-2"> 22093.10 </span>
          <span class="text-green-400">+305.30 (+1.40%)</span>
        </div>
      </div>
      <div class="ticker-wrap-item">
        <div class="flex items-center">
          <span class="mr-2">
            <img src="/static-images/usd-eur.png" style="height: 20px" />
          </span>
          <span class="mr-2"><strong>EUR</strong></span>
          <span class="mr-2"> 1.07 </span>
          <span class="text-green-400">+0.01 (+0.15%)</span>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.ticker-wrap {
  /* position: fixed; */
  /* bottom: 0; */
  width: 100%;
  overflow: hidden;
  height: 3rem;
  /* background-color: rgba(#000, 0.9); */
  /* padding-left: 100%; */
  box-sizing: content-box;
  background-color: white;
  position: relative;
}

.ticker-wrap-item {
  display: inline-block;
  padding: 0 1rem;
  /* font-size: 2rem; */
  color: black;
}

.ticker {
  display: inline-block;
  height: 3rem;
  line-height: 3rem;
  white-space: nowrap;
  padding-right: 100%;
  box-sizing: content-box;

  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-name: ticker;
  animation-name: ticker;
  -webkit-animation-duration: 20s;
  animation-duration: 20s;
}
</style>
