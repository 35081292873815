import { createRouter, createWebHistory } from "vue-router";
import HomeViewTemp from "../views/HomeViewTemp.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeViewTemp,
  },
  {
    path: "/about",
    name: "about",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  {
    path: "/season/:season",
    name: "season",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/SeasonView.vue"),
    props: (route) => ({
      season: route.params.season,
    }),
  },
  {
    path: "/season/:season/:token",
    name: "season-token",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/SeasonTokenView.vue"),
    props: (route) => ({
      season: route.params.season,
      token: route.params.token,
    }),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
