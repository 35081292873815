<script setup>
import { useRoute } from "vue-router";
import ToastServiceComponent from "@/components/ToastServiceComponent.vue";

const route = useRoute();
</script>

<template>
  <router-view :key="route.fullPath" />
  <ToastServiceComponent />
</template>

<style>
</style>
