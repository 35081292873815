import { createStore } from "vuex";
import { make } from "@/request";
import Web3Modal from "web3modal";
import { ethers } from "ethers";
import WalletConnectProvider from "@walletconnect/web3-provider";
import Toast from "@/classes/Toast";
// import ErrorParse from "@/classes/ErrorParse";
import { markRaw } from "vue";
import contractJson from "@/assets/contract.json";
import seasonsJson from "@/assets/seasons.json";
import Season from "@/classes/Season";

// local: 0xD197e09ee400B4a1CAE4CD0c5a734D4c6fc6C6Ed

const seasons = seasonsJson.map((s) => new Season(s));

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    auth: {
      namespaced: true,
      state: {
        address: null,
        user: null,
        connection: null,
        rpc: null,
        mainnet: null,
      },
      mutations: {
        SET_ADDRESS(state, payload) {
          state.address = payload;
        },
        SET_USER(state, payload) {
          state.user = payload;
        },
        SET_CONNECTION(state, payload) {
          state.connection = {
            signer: markRaw(payload?.signer),
            provider: markRaw(payload?.provider),
            address: payload?.address,
            network: markRaw(payload?.network),
          };
        },
        SET_RPC(state, payload) {
          state.rpc = markRaw(payload);
        },
        SET_MAINNET(state, payload) {
          state.mainnet = markRaw(payload);
        },
        CLEAR_CONNECTION(state) {
          state.connection = null;
          state.rpc = null;
          state.mainnet = null;
        },
      },
      actions: {
        async signup(context, email) {
          let request;
          await make({ name: "getCsrfCookie" });
          request = await make({ name: "signup", data: { email } });

          return request;
        },
        async clearListeners({ state }) {
          if (state?.connection?.provider) {
            state.connection.provider.provider.removeAllListeners();
          }
          return;
        },
        async startListeners({ commit, dispatch, state }) {
          await dispatch("clearListeners");

          if (state?.connection?.provider) {
            state?.connection?.provider.provider.on(
              "accountsChanged",
              (accounts) => {
                console.log("account changed: ", accounts);
                dispatch("connect", true);
              }
            );

            // Subscribe to chainId change
            state?.connection?.provider.provider.on(
              "chainChanged",
              (chainId) => {
                console.log("Chain changed: ", chainId);
                dispatch("connect", true);
              }
            );

            // Subscribe to session disconnection
            state?.connection?.provider.provider.on(
              "disconnect",
              (code, reason) => {
                console.log("Walled disconnected: ", code, reason);
                commit("CLEAR_CONNECTION");
              }
            );
          }

          return;
        },
        async connect({ commit, dispatch, state }, listenersOn) {
          await dispatch("clearListeners");

          const providerOptions = {
            walletconnect: {
              package: WalletConnectProvider, // required
              options: {
                infuraId: "c0f74ca6e1f84279b3009347a36b6a77", // required
              },
            },
          };

          const web3Modal = new Web3Modal({
            network: "mainnet", // optional
            cacheProvider: false, // optional
            providerOptions, // required
          });

          let signer, provider, network;
          try {
            const cleared = await web3Modal.clearCachedProvider();
            const instance = await web3Modal.connect();
            provider = new ethers.providers.Web3Provider(instance);
            signer = await provider.getSigner();
            network = await provider.getNetwork();
          } catch (e) {
            console.log("web3 rejected", { e });
            dispatch(
              "toasts/add",
              {
                message: "Web3 connection request rejected by user",
                variant: "error",
              },
              { root: true }
            );
            commit("CLEAR_CONNECTION");
            return false;
          }

          const address = await signer.getAddress();

          commit("SET_CONNECTION", { signer, provider, address, network });

          if (listenersOn) {
            dispatch("startListeners");
          }

          return { signer, provider, address, network };
        },
        async disconnect({ commit, dispatch }) {
          await dispatch("clearListeners");
          commit("CLEAR_CONNECTION");
          return;
        },
        async provider({ commit, state }) {
          return state.connection?.provider;
          // let rpc = state.connection?.provider ?? state.rpc;

          // if (!rpc) {
          //   rpc = new ethers.providers.WebSocketProvider(
          //     process.env.VUE_APP_RPC_PROVIDER
          //   );

          //   commit("SET_RPC", rpc);
          // }

          // return rpc;
        },
        async contract({ commit, dispatch, state }) {
          // connect to the deployed contract
          let provider;
          if (state.connection?.signer) {
            if (
              state?.connection?.network?.chainId ===
              parseInt(process.env.VUE_APP_CONTRACT_NETWORK_ID)
            ) {
              provider = state.connection.signer;
            }
          } else {
            // provider = await dispatch("provider");
            return null;
          }

          if (!provider) {
            return null;
          }

          let contractInstance = await new ethers.Contract(
            process.env.VUE_APP_CONTRACT_ADDRESS,
            contractJson.abi,
            provider
          );

          return contractInstance;
        },
        async mint({ state, dispatch }, { qty, id }) {
          if (!state.connection?.signer) {
            dispatch(
              "toasts/add",
              { message: "Must connect your wallet to mint", variant: "error" },
              { root: true }
            );
            return;
          }

          if (
            state?.connection?.network?.chainId !==
            parseInt(process.env.VUE_APP_CONTRACT_NETWORK_ID)
          ) {
            dispatch(
              "toasts/add",
              { message: "Must be on Ethereum Mainnet", variant: "error" },
              { root: true }
            );
            return;
          }

          let contractInstance = await new ethers.Contract(
            process.env.VUE_APP_CONTRACT_ADDRESS,
            contractJson.abi,
            state.connection.signer
          );

          let bigNumAmount = await contractInstance.price(id);
          let valMultiplied = bigNumAmount.mul(qty);
          let valForTransaction = valMultiplied;

          let shopAttempt;
          try {
            shopAttempt = await contractInstance["shop(uint256,uint256)"](
              id,
              qty,
              {
                value: valForTransaction,
              }
            );
          } catch (e) {
            dispatch(
              "toasts/add",
              {
                message: e?.data?.message ?? e?.message ?? e,
                variant: "error",
              },
              { root: true }
            );
            return;
          }

          dispatch(
            "toasts/add",
            { message: "Minting will confirm soon", variant: "success" },
            { root: true }
          );

          return shopAttempt;
        },
        async ens({ dispatch, state, commit }, address) {
          // const address = await provider.lookupAddress(hx);
          // console.log(address ?? "NONE");

          let provider;
          if (state.connection?.provider) {
            if (state?.connection?.network?.chainId === 1) {
              provider = state.connection.provider;
            }
          }

          if (!provider) {
            return;
          }

          let name = await provider.lookupAddress(address);

          return name;
        },
      },
      getters: {
        isLoggedIn(state) {
          return !!state?.address;
        },
      },
    },
    toasts: {
      namespaced: true,
      state: {
        stack: [],
        index: 1,
      },
      mutations: {
        ADD_TOAST(state, payload) {
          state.index += 1;
          // Vue.set(state, "stack", [...state.stack, payload]);
          state.stack = [...state.stack, payload];
        },
        REMOVE_TOAST(state, payload) {
          let newStack = state.stack.slice();
          const foundIndex = payload.id
            ? newStack.findIndex((val) => val.id === payload.id)
            : newStack.findIndex((val) => val.message === payload.message);
          newStack.splice(foundIndex, 1);
          // Vue.set(state, "stack", [...newStack]);
          state.stack = [...newStack];
        },
        CLEAR_ALL(state) {
          state.stack = [];
          // Vue.set(state, "stack", []);
        },
      },
      actions: {
        add({ commit, state }, payload) {
          let added = payload;
          if (!(payload instanceof Toast)) {
            added = new Toast({ ...payload, id: state.index });
          }

          commit("ADD_TOAST", added);

          return added;
        },
        remove({ commit }, payload) {
          let removed = payload;
          if (!(payload instanceof Toast)) {
            removed = new Toast(payload);
          }

          commit("REMOVE_TOAST", removed);

          return true;
        },
      },
      getters: {
        getStack: (state) => state.stack,
      },
    },
    seasons: {
      namespaced: true,
      state: {
        list: seasons,
      },
      actions: {
        async fetch(context, number) {
          await make({ name: "getCsrfCookie" });
          let request = await make({ name: "season", data: { number } });

          return request;
        },
        async token(context, data) {
          await make({ name: "getCsrfCookie" });
          let request = await make({ name: "token", data });

          return request;
        },
      },
    },
  },
});
