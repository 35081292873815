<script setup>
import { defineProps, reactive, ref, onMounted, onUnmounted } from "vue";
import videojs from "video.js";

const player = ref(null);

const videojsInstance = ref(null);

const props = defineProps({
  video: String,
  screenshot: String,
  type: {
    default: "video/mp4",
  },
  autoplay: Boolean,
});

const options = reactive({
  autoplay: true,
  controls: true,
  // responsive: true,
  aspectRatio: "16:9",
  sources: [
    {
      src: props.video,
      type: "video/mp4",
    },
  ],
});

const showVideo = ref(false);

const showVideoFunc = () => {
  console.log({
    video: props.video,
    screenshot: props.screenshot,
  });
  showVideo.value = true;
  setTimeout(() => {
    videojsInstance.value = videojs(player.value, options);
  });
};

onMounted(() => {});

onUnmounted(() => {
  if (videojsInstance?.value) {
    videojsInstance.value?.dispose();
  }
});
</script>

<template>
  <div class="w-full relative">
    <template v-if="showVideo">
      <video ref="player" class="video-js vjs-default-skin"></video>
    </template>
    <template v-else>
      <img
        :src="props.screenshot"
        class="relative cursor-pointer w-full"
        @click="showVideoFunc"
      />
      <div
        class="w-full h-full absolute flex items-center justify-center top-0 left-0"
      >
        <img
          :src="require('@/assets/play.png')"
          class="cursor-pointer"
          style="height: 60px"
          @click="showVideoFunc"
        />
      </div>
    </template>
  </div>
</template>

<style scoped></style>
